<template>
<div style="margin-top: 20px">
  <div>
    <span class="label">附件</span>
    <el-button type="text" @click="handleAdd"  >添加附件</el-button>
    <span class="tips">(最多上传10个附件，单个最大20M)</span>
  </div>
  <ul class="file-list flexr">
    <li class="flexr justify-between" v-for="(file,index) in fileList" :key="index">
      <div class="flexr  file-left">
        <img src="@/assets/images/delete.png" alt="" @click="deleteFile(index)">
        <p class="elOne">{{getFileName(file)}}</p>
      </div>
      <div class="flexr">
        <a class="file-operation flexr" target="_blank" :href="baseUrl+file"><img src="@/assets/images/views.png" alt="">
<!--          <span>2</span>-->
        </a>
        <a class="file-operation flexr" target="_blank" :href="baseUrl+file" download=""><img src="@/assets/images/download.png" alt="">
<!--          <span>2</span>-->
        </a>
      </div>

    </li>
  </ul>

  <!-- 添加或修改岗位对话框 -->
  <el-dialog title="附件上传" :visible.sync="fileOpen" width="500px" append-to-body>
    <el-form ref="form" :model="form" :rules="rules" label-width="100px">
      <el-form-item label="上传文件" prop="file">
        <FileUpload v-model="form.file"></FileUpload>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm">确 定</el-button>
      <el-button @click="cancel">取 消</el-button>
    </div>
  </el-dialog>
</div>
</template>

<script>
import {addFormLogFile} from "@/api/login"
export default {
  name: 'file',
  props:{
    list:{
      type:Array,
      default:()=>{return []}
    },
    id:{
      type:[Number,String],
      default:null
    }
  },
  data(){
    return{
      fileOpen:false,
      form:{
        file:''
      },
      rules:{file: [
      {required: true, message: '请选择附件', trigger: 'change'}
    ],},
      fileList:[],
      baseUrl:process.env.VUE_APP_BASE_API
    }
  },
  watch:{
    list(val){
      this.fileList=val
    }
  },
  mounted() {
    this.fileList=this.list
  },
  methods:{
    getFileName(url){
      let index=url.lastIndexOf('/')
      let str=url.substring(index+1)
      return str
    },
    deleteFile(index){
      this.$confirm('是否确认删除当前文件?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.fileList.splice(index,1)
        addFormLogFile({id:this.id,files: JSON.stringify(this.fileList) }).then(res=>{
          this.cancel()
        })
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });
      });
    },
    submitForm(){

      this.$refs.form.validate(valid => {
        if (!valid) return
        let list=this.form.file.split(',')
        let array=this.fileList=this.fileList.concat(list)
        addFormLogFile({id:this.id,files: JSON.stringify(array) }).then(res=>{
        this.cancel()
        })

      })

    },
    cancel(){
      this.fileOpen=false
    },
    handleAdd(){
      this.fileOpen=true
      this.form={
        file:''
      }
    }
  }
}
</script>

<style lang="less" scoped>
.file-list{
  flex-wrap: wrap;
  .file-left{
    width: calc(100% - 60px);
  }
  img{
    height: 14px;
    width: auto;
    margin-right: 10px;
    cursor: pointer;
  }
  li{
    width: 31%;
    margin:10px 1.1% 10px;
    background: #F5F5F7;
    border-radius: 6px;
    padding:10px;
  }

  p{
    font-size: 14px;
    color: #1890ff;
    width: 80%;
    height: 20px;
    line-height: 20px;
  }

}
.label{
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  line-height: 32px;
  text-align: left;
  font-style: normal;
  margin-right: 20px;
}
.tips{
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #999999;
  line-height: 32px;
  text-align: left;
  font-style: normal;
  margin-left: 20px;
}
.file-operation {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  line-height: 14px;
  text-align: center;
  font-style: normal;
  margin-right: 10px;
  cursor: pointer;
}
</style>
