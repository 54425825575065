import Vue from 'vue'
import Vuex from 'vuex'
import {getInfo} from "@/api/login";
import router from "@/router";
Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        user: {},
        haveLogin: false,
        loginType: 'person'
    },
    mutations: {
        GET_USER: (state, value) => {
            state.user = value
        },
        GET_LOGIN: (state, value) => {
            state.haveLogin = value
        },
        SET_LOGIN_TYPE: (state, value) => {
            state.loginType = value
        }
    },
    actions: {

        geInfos({commit}, localeVal) {
            getInfo().then(res=>{
                console.log('------------')
                if(res.code==200&&res.user&&res.user.childType){
                    let path=window.location.href
                    console.log(path)
                    if(res.user.childType==3&&path.indexOf('/setform')==-1){
                        router.push('/')
                    }else if((res.user.childType==2||res.user.childType==1)&&path.indexOf('/index')==-1){

                        router.push('/index')
                    }
                }
            })
            // getCurrentStation({
            // 	code: localeVal
            // }).then(result => {
            // 	if (result.code == 200) {
            // 		uni.setStorageSync('Scode', result.data.code)
            // 		uni.setStorageSync('Sname', result.data.name)
            // 		uni.setStorageSync('Sid', result.data.id)
            // 		uni.setStorageSync('enableStatus', result.data.enableStatus)
            // 		commit('enableStatus', result.data.enableStatus)
            // 		commit('stationTitle', result.data.name)
            // 		commit('disinfect', result.data.disinfect)
            // 		// uni.$emit('prepare')
            // 	} else {
            // 		this.$refs.mToast.showToast('');
            // 	}
            // })
        },
    }
})
export default store
