import { render, staticRenderFns } from "./appSetForm.vue?vue&type=template&id=260a519c&scoped=true"
import script from "./appSetForm.vue?vue&type=script&lang=js"
export * from "./appSetForm.vue?vue&type=script&lang=js"
import style0 from "./appSetForm.vue?vue&type=style&index=0&id=260a519c&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "260a519c",
  null
  
)

export default component.exports