<template>
  <div class="nav" ref="nav" :class="isFixed ? `is_fixed` : ''">
    <transition name="moveUp">
      <div class="navinner" v-show="show">
      </div>
    </transition>
    <transition>
      <div class="content" v-show="show">
        <div class="navMenu justify-between " @mouseleave="mouseleave()">
          <!-- <div class="active-bar"></div> -->
          <div class="flexr">
          <div class="subMenu" v-for="(item, k) in menuList" :key="k">
            <div class="menuTitle" @click="toUrl(item.path, item, { title: item.meta.title }, k)"
                 @mouseenter="mouseenter(k)">
              <div class="metaTitle" :data-text="item.meta.title">{{ item.meta.title }}</div>
              <div :class="activeMenu == k ? 'activeBar calcWith' : 'activeBar'"></div>
            </div>
            <div class="menuItem" v-if="item.children && item.children.length > 0">
              <template v-for="(el, i) in item.children" v-if="el.authType">
                <div class="item"  :key="i"
                     v-show="el.authType == 'default' || el.authType.indexOf(userType) != -1 || el.authType.indexOf(userType) == -1 && el.authNoticeType != 'hide'">
                  <div class="itemTitle"
                       @click="toUrl(el.path, el, { title: el.meta.title, mode: el.query }, k)">
                    {{
                      el.meta.title }}
                  </div>
                </div>
              </template>

            </div>
          </div>
          </div>
          <div class="back-font">
            返回光谷人
          </div>
        </div>
      </div>
    </transition>

  </div>
</template>

<script>
// import { GetPolicyNoticeList, GetJobInfoValidEnterpriseInfo } from '@/api/newhome/index.js'
// import { getSystemInfo } from '@/api/personal'
// import { applyStatus, applyCall } from '@/api/talentsMarket/talentsApply'
export default {
  props: {
    menuList: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      activeMenu: 2,
      show: false,
      isFixed: false,
      offsetTop: 0,
      applyStau: {},
      callApply: false, // 是否发起申请
    }
  },
  // watch: {
  //     activeMenu(newVal, oldVal) {
  //         console.log(newVal, oldVal);
  //     }
  // },
  computed: {
    userType() {
      //判断是个人还是企业
      return this.$store.state.loginType
    }
  },
  mounted() {
    this.show = true
    window.addEventListener('scroll', this.initHeight);
    // this.$nextTick(() => {
    //获取对象相对于版面或由 offsetTop 属性指定的父坐标的计算顶端位置
    this.offsetTop = this.$refs.nav.offsetTop
    // })
    // if (sessionStorage.getItem('activeHomeBar')) {
    //     let k = Number(sessionStorage.getItem('activeHomeBar'))
    //     console.log(111111111111, sessionStorage.getItem('activeHomeBar'), k);
    //     this.moveActive(k)
    //     this.activeMenu = k

    // }
  },
  //回调中移除监听
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    // 政策须知
    getPolicyNoticeList() {
      let params = {
        page: 1,
        pageSize: 10,
        status: 1,
      }
      //TODO 暂未使用
      // GetPolicyNoticeList(params).then(res => {

      //     if (res.total == 1) {
      //         this.$router.push({ path: '/second/NewsDetail', query: { title: '政策须知', key: res.rows[0].id } })
      //     } else {
      //         this.$router.push({ path: '/second/Informationlist', query: { title: '政策须知', key: 2 } })
      //     }

      // })
    },
    // 资格认定
    //TODO 暂未使用
    async handleApplyHousing() {
      if (!this.$store.state.haveLogin) {
        this.$confirm('您当前还未登录,请先登录个人账号!', '提示', {
          confirmButtonText: '去登录',
          cancelButtonText: '取消',
          confirmButtonClass: 'okk',
          // type: 'warning'
        }).then(() => {
          this.goLogin();
        }).catch(() => {
          return false
        })
        return false
      }
      getSystemInfo().then(async (res) => {
        if (!res.data.idNumber || !res.data.phone || !res.data.sex || !res.data.name) {
          this.$confirm('请先完善个人信息!', '提示', {
            confirmButtonText: '去完善',
            confirmButtonClass: 'okk',
            customClass: 'renCaiMsg',
            // type: 'warning'
          }).then(() => {
            this.goLocation('/personalWorkStation')
          })
        } else {
          let { data: applyStau } = await applyStatus()
          this.applyStau = applyStau
          if (applyStau.statusCd == 1 || applyStau.statusCd == 2) {
            return this.$message({
              message: '您已有人才住房优惠资格，无需申请',
              type: 'warning',
            })
          }
          let { data: callApply } = await applyCall()
          this.callApply = callApply
          if (this.callApply) {
            if (this.callApply.examineLogs && this.callApply.examineLogs[0].approvalStatus != 3) {
              return this.$message({
                message: '您正在进行资格审核，无需重复申请',
                type: 'warning',
              })
            }
          }
          this.goLocation('/second/talentsMarketApplyForm')
        }
      })
    },
    // 我的申请
    handleViewMyApply() {
      if (!this.$store.state.haveLogin) {
        this.$confirm('您当前还未登录,请先登录个人账号!', '提示', {
          confirmButtonText: '去登录',
          cancelButtonText: '取消',
          confirmButtonClass: 'okk',
          // type: 'warning'
        }).then(() => {
          this.goLogin();
        }).catch(() => {
          return false
        })
        return false
      }
      this.goLocation('/second/myApply?self=1')
    },
    // 园区运营
    judgePark(row) {
      if (!this.$store.state.haveLogin) {
        // this.$router.push({path:"/login",query:{t:new Date().getMilliseconds()}})
        this.$confirm('请先以企业单位主体登录', '提示', {
          confirmButtonText: '去登录',
          cancelButtonText: '取消',
          confirmButtonClass: 'okk',
          // type: 'warning'
        }).then(() => {
          this.goLogin();
        })
      } else if (this.userType == 'person') {
        this.$message({ message: '当前为人才账号，请使用企业单位账号登录后进行操作！', type: 'warning' })
      } else {
        window.open(row.path)
      }

    },
    // 检查企业信息是否完整
    getJobInfoValidEnterpriseInfo() {
      if (this.userType != 'enterprise') return this.$message.error('请登录企业账号查看')
      GetJobInfoValidEnterpriseInfo().then(res => {
        if (!res.data) {
          return this.$message.error('请完善企业信息')
        }
      })
    },
    initHeight() {
      // 设置或获取位于对象最顶端和窗口中可见内容的最顶端之间的距离 (被卷曲的高度)
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      //如果被卷曲的高度大于吸顶元素到顶端位置 的距离
      this.isFixed = scrollTop > this.offsetTop ? true : false;
    },
    mouseenter(k) {
      this.moveActive(k)
    },
    mouseleave() {
      this.moveActive(this.activeMenu)
    },
    toUrl(path, row, query, k) {
      if (path == '敬请期待') return this.$message.warning(path)
      if (row.authType&&row.authType != 'default' && row.authType.indexOf(this.userType) == -1 && row.authNoticeType == 'warn') return this.$message.warning('敬请期待')
      if (row.meta.title == '我的申请' || row.meta.title == '资格认定') {
        if (this.$store.state.haveLogin) {
          if (this.userType == 'sys_user' || this.userType == 'enterprise') {
            return this.$message.error('仅对个人用户开放')
          }
        }
      }
      if (row.meta.title == '园区运营') return this.judgePark(row)
      if (row.isFrame == 0) return window.open(path)
      if (row.path == '/second/Informationlist' || row.path == '/second/talentsMarketApplyForm') {
        return this.goLocation('/second/MenuLoading?path='+row.path )
      }
      // if (row.meta.title == '政策须知') return this.getPolicyNoticeList()
      if (row.meta.title == '我的申请') return this.handleViewMyApply()
      // if (row.meta.title == '资格认定') return this.handleApplyHousing()
      // if (row.meta.title == '招人才') {
      //     if (this.userType != 'enterprise') return this.$message.error('请登录企业账号查看')
      // }
      if (row.meta.title === '3551创业大赛') return this.openLocation(path)
      this.moveActive(k)
      this.activeMenu = k
      sessionStorage.setItem('activeHomeBar', this.activeMenu)
      let url = {
        path,
      }
      let params = {}
      if (query) {
        params = query
        url.query = params
      }
      let redirect =this.toFixUrl(url);
      window.location.href= redirect;
    },
    moveActive(n) {
      // this.$nextTick(() => {
      // let ele = document.getElementsByClassName('active-bar')[0]
      // let subMenuEle = document.getElementsByClassName('subMenu')[n]
      // ele.style.transform = `translateX(${subMenuEle.offsetLeft + 20}px)`
      // ele.style.width = subMenuEle.offsetWidth - 40 + 'px'
      // })
    },
    toFixUrl(item){
      let prefix =process.env.VUE_APP_BASE_SSO;
      if(item.isFrame=="0"){
        return item.path
      }else{
        let url = prefix ;
        if(item.path.indexOf('/') ===0){
          url = prefix + item.path;
        }else{
          url = prefix +'/'+ item.path;
        }
        if(item.query && item.query.mode){
          url = url + (url.indexOf('?')==-1 ? '?' :' &') + 'mode='+item.query.mode
        }
        return url;
      }
    },
    goLogin() {
      // this.$message.error("跳转到登录");
      const url =encodeURIComponent(window.location.href);
      window.location.href=process.env.VUE_APP_BASE_SSO+"/LoginRegister?redirect="+url+'&type=link';
    },
    goLocation(location){
      window.location.href=process.env.VUE_APP_BASE_SSO+location;
    },
    openLocation(location){
      window.open(process.env.VUE_APP_BASE_SSO + '/' + location)
    }
  }
}
</script>

<style lang="less" scoped>
// .v-enter {
//     transform: translateY(44px);
// }

// .v-enter-active {
//     animation: move 3s;
// }

// .v-enter-to {
//     transform: translateY(0%);

// }

.moveUp-enter-active {
  animation: moveUp 0.2s ease-in;
}

@keyframes moveUp {
  0% {

    overflow: hidden;
    height: 44px;
  }

  100% {
    height: 0;
    overflow: visible;
  }
}

@keyframes move {
  0% {
    // transform: translateY(44px);
    height: 44px;
    opacity: 0;
    background: #fff;
    overflow: hidden;
  }

  50% {
    overflow: hidden;
  }

  100% {
    // transform: translateY(0%);
    height: 44px;
    opacity: 1;
    background: rgba(44, 60, 111, 0.7);
    overflow: visible;
  }
}


.nav {
  width: 100%;
  height: 44px;
  position: absolute;
  bottom: 0;
  z-index: 999;

  .navinner {
    width: 100%;
    height: 0px;
    background: linear-gradient(180deg, #EFF3FA 0%, #EFF3FA 50%, #fff 100%);
    position: absolute;
    top: 0;
    z-index: 10;
  }

  .content {
    width: 100%;
    height: 44px;
    background: rgba(44, 60, 111, 0.8);
    position: absolute;
    bottom: -2px;

  }

  .navMenu {
    width: 1340px;
    height: 44px;
    margin: 0 auto;
    display: flex;
     justify-content: flex-start;
    //justify-content: space-between;
    color: #FFF;
    font-family: Source Han Sans CN;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
    user-select: none;
    position: relative;

    .active-bar {
      position: absolute;
      bottom: 6px;
      left: 0;
      height: 1px;
      background: #FFF;
      z-index: 1;
      transition: transform .3s cubic-bezier(.645, .045, .355, 1);
    }

    .subMenu {
      height: 100%;
      position: relative;
      z-index: 999;
      // overflow: hidden;

      .menuTitle {
        height: 100%;
        display: flex;
        align-items: center;
        // justify-content: space-around;
        // position: relative;

        .metaTitle {
          padding: 0 28px;
          border-right: 1px solid #fff;

          text-align: center;
          white-space: nowrap;

          // 为了保证菜单字体加粗后不变形，提前用伪元素占据加粗所需的空间
          &::after {
            display: block;
            content: attr(data-text);
            font-weight: bold;
            visibility: hidden;
            height: 0;
            color: transparent;
            margin-bottom: 0;
            overflow: hidden;
          }
        }

        .activeBar {
          position: absolute;
          bottom: 6px;
          left: 28px;
          z-index: 2;
          width: 0px;
          height: 2.3px;
          background: #fff;

        }

        .calcWith {
          width: calc(100% - 56px);
        }

        .activeBar {
          position: absolute;
          bottom: 6px;
          left: 28px;
          z-index: 2;
          width: 0px;
          height: 2.3px;
          background: #fff;

        }

        .calcWith {
          width: calc(100% - 56px);
        }
      }

      &:last-child .menuTitle>div {
        border-right: 0
      }

      &:hover {
        font-weight: bold;

        .menuItem {

          max-height: 500px;
          font-weight: normal;
          opacity: 1;
        }

        .activeBar {
          width: calc(100% - 56px);
          transition: 0.4s ease-in;
        }
      }

      .menuItem {
        opacity: 0;
        width: 216px;
        max-height: 0px;
        overflow: hidden;
        transition: all 1s linear;
        backdrop-filter: blur(7.5px);
        position: absolute;
        left: 0;
        top: 45px;
        z-index: 999;
        display: flex;
        flex-direction: column;
        background: rgba(44, 60, 111, 0.7);

        .item {
          width: 100%;
          margin-bottom: 12px;
          padding-left: 20px;

          &:nth-child(1) {
            margin-top: 25px;
          }

          &:last-child {
            margin-bottom: 18px;
          }

          &:hover {
            font-weight: bold;
            background: rgba(255, 255, 255, 0.20);

          }
        }


      }
    }
  }

}

.is_fixed {
  width: 100%;
  height: 44px;
  position: fixed;
  top: 0;
  z-index: 999;
}
.back-font{
  line-height: 44px;
}
</style>
