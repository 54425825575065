<template>
  <div>
    <topNav>
      <img class="sub-nav-icon" src="../assets/img-new/icon-home.png"/>
      <span class="sub-nav-item active" @click="gotoPage(1)">> 工程师协会 ></span>
      <span class="sub-nav-item active" @click="gotoPage(2)"> 工程硕博士 ></span>
      <span class="sub-nav-item">工程硕博士服务平台</span>
    </topNav>
    <div class="main-wrap padding_20">
      <el-form :inline="true" ref="queryParams" :model="queryParams" class="demo-form-inline" size="mini">
        <el-form-item label="" prop="name">
          <el-input class="inputWidth"
              v-model="queryParams.name"
              placeholder="学生姓名"
              clearable
          />
        </el-form-item>
        <el-form-item label="" prop="identNum">
          <el-input class="inputWidth"
              v-model="queryParams.identNum"
              placeholder="身份证号"
              clearable
          />
        </el-form-item>
        <el-form-item label="" prop="degreeType">
          <el-select class="inputWidth" v-model="queryParams.degreeType" placeholder="学位类型" clearable>
            <el-option v-for="(item,index) in degreeTypeOptions" :key="index" :label="item.dictLable"
                       :value="item.dictValue"></el-option>

          </el-select>
        </el-form-item>
        <el-form-item label="" prop="period">
          <el-date-picker class="inputWidth"
              v-model="queryParams.period"
              type="year"
              value-format="yyyy"
              placeholder="年级" clearable
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="" prop="schoolState">
          <el-select class="inputWidth" v-model="queryParams.schoolState" placeholder="学生状态" clearable>
            <el-option v-for="(item,index) in schoolStateMap" :key="index" :label="item" :value="index"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="rqFlag">
          <el-select class="inputWidth" v-model="queryParams.rqFlag" placeholder="入企状态" clearable>
            <el-option v-for="(item,index) in rqFlagOption" :key="index" :label="item.dictLable"
                       :value="item.dictValue"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="experimentSchool">
          <el-input class="inputWidth"
              v-model="queryParams.experimentSchool"
              placeholder="试点高校"
              clearable @keyup.enter.native="handleQuery" @clear="handleQuery"
          />
        </el-form-item>
        <el-form-item  prop="pilotEnterprise">
          <el-input class="inputWidth"
              v-model="queryParams.pilotEnterprise"
              placeholder="招生指标试点企业"
              clearable @keyup.enter.native="handleQuery" @clear="handleQuery"
          />
        </el-form-item>
        <el-form-item  prop="experimentFocalUnit">
          <el-input class="inputWidth"
              v-model="queryParams.experimentFocalUnit"
              placeholder="实际试点归口单位"
              clearable @keyup.enter.native="handleQuery" @clear="handleQuery"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleQuery">查询</el-button>
          <el-button type="" @click="resetQuery">重置</el-button>
          <el-button type="" @click="handleExportReport">导出学生信息</el-button>
        </el-form-item>
      </el-form>
      <el-table border v-loading="loading"
                :data="tableData"
                style="width: 100%">
        <el-table-column type="index" label="序号" width="55"/>
        <el-table-column label="学生姓名" align="center" prop="name"/>

        <el-table-column label="身份证号" align="center" prop="identNum" min-width="100" :show-overflow-tooltip="true"/>
        <el-table-column label="性别" align="center" prop="sex"/>
        <el-table-column label="手机号" align="center" prop="phonenumber" min-width="140"/>
        <!--  1 博士 2 硕士     -->
        <el-table-column label="实际入企实践企业" align="center" prop="joinEnterprise" width="160"
                         :show-overflow-tooltip="true"/>
        <el-table-column label="学位类型" align="center" prop="learningCategory"/>
        <el-table-column label="试点高校" align="center" prop="experimentSchool" min-width="100"
                         :show-overflow-tooltip="true"/>
        <el-table-column label="年级" align="center" prop="period"/>
        <el-table-column label="院系" align="center" prop="faculty"/>
        <el-table-column label="专业" align="center" prop="professionalName"/>
        <el-table-column label="学生状态" align="center" prop="schoolState">
          <template slot-scope="scope">
            {{ schoolStateMap[scope.row.schoolState] }}
          </template>
        </el-table-column>
        <el-table-column prop="date" label="操作">
          <template slot-scope="scope">
            <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                @click="handlelook(scope.row)"

            >学生信息
            </el-button>

          </template>
        </el-table-column>

      </el-table>
      <div class="flex justify-center padding_20">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="queryParams.pageNum"
            :page-size.sync="queryParams.pageSize"
            background
            layout="prev, pager, next, total"
            :total="total">
        </el-pagination>
      </div>

    </div>
  </div>
</template>

<script>
import topNav from "../components/topNav.vue"
import {getDicts, getMyStuList} from "@/api/login.js"

export default {
  name: 'IndexPage',
  components: {
    topNav
  },
  data() {
    return {
      queryParams: {
        pageSize: 10,
        pageNum: 1,
        name: '',
        identNum: '',
        learningCategory: '',
        degreeType: '',
        period: '',
        schoolState: "",
        experimentSchool:'',
        pilotEnterprise:'',
        experimentFocalUnit:''
      },
      tableData: [],
      degreeTypeOptions: [],
      total: 0,
      loading: false,
      schoolStateMap: {
        '1': '在校',
        '2': '已毕业'
      },
      rqFlagOption:[]
    }
  },
  mounted() {
    getDicts('degree_type').then(res => {
      this.degreeTypeOptions = res.data
    })
    getDicts('entry_status').then(res => {
      this.rqFlagOption = res.data
    })
    this.getList()
  },
  activated() {

    this.getList()
  },
  methods: {
    gotoPage(type){
      if(type==1){
        window.location.href=process.env.VUE_APP_BASE_SSO+"/second/EngineersInstitute"
      }else{
        window.location.href=process.env.VUE_APP_BASE_SSO+"/EngineersInstitute/MasterDoctor?title=工程硕博士"
      }
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.$refs['queryParams'].resetFields();
      this.handleQuery();
    },
    handlelook(row) {
      this.$router.push({path: '/setForm', query: {id: row.id}})
    },
    getList() {
      this.loading = true;
      getMyStuList(this.queryParams).then(response => {

        if (response && response.code == 200) {
          this.tableData = response.rows;
          this.total = response.total;
          this.loading = false;
        } else {
          this.tableData = [];
          this.total = 0;
          this.loading = false;
        }

      }, () => {
        this.loading = false;
      })
    },
    handleSizeChange() {
      this.getList()
    },
    handleCurrentChange() {
      this.getList()
    },
    /** 导出学生信息按钮操作 */
    handleExportReport() {
      let fileName = '学生信息.xlsx'
      this.download('third/exportReport', {
        ...this.queryParams,
      }, fileName)
    }
  }
}
</script>

<style scoped lang="less">
.inputWidth{
  width: 180px;
}
</style>
