import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";
import index from '../views/index'
import setform from '../views/setform.vue'
import appSetForm from '../views/appSetForm.vue'
import page from '../views/page.vue'
// eslint-disable-next-line no-unused-vars
import { jsonp } from 'vue-jsonp'
import {arrayChangeKey} from '@/utils/index.js'
import {ssoLogin ,ssoCheck} from '../api/login.js'
import {
    setToken,
    removeToken
} from '@/utils/auth'


Vue.use(VueRouter)
// 创建路由器

const routes=[
    {
        // 访问根路径时，重定向到login组件
        path: '/',
        redirect: '/page'
    },
    {
        name:'IndexPage', 	  // 路由名
        path:'/index',	  // 路由路径
        component:index   // 对应组件名
    },{
        name:'Setform', 	  // 路由名
        path:'/setform',	  // 路由路径
        component:setform   // 对应组件名
    },
    {
        name:'AppSetForm', 	  // 路由名
        path:'/appSetForm',	  // 路由路径
        component:appSetForm   // 对应组件名
    },
    {
        name:'page', 	  // 路由名
        path:'/page',	  // 路由路径
        component:page   // 对应组件名
    }
]

const routesMap =arrayChangeKey(routes ,'name');
const router= new VueRouter({
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition && to.meta.keepAlive) {
            return savedPosition;
        }
        return { x: 0, y:0 };
    },
    base:'/',
    routes: routes
})

router.beforeEach(async (to, from, next) => {
    let oldssoToken=window.localStorage.getItem('ssoToken' );

    let ssoRes =await jsonp(process.env.VUE_APP_BASE_SSO+'/prod-api/external/user/jsonp', {
        callbackQuery: 'callback',
        callbackName: 'callback_login',
        r:Math.random()*100000000
    },3000).catch(err=>{
        console.log('jsonp error:',err)
    })

    if(ssoRes){
        if(ssoRes.code==200 && ssoRes.token){
            //登录
            window.localStorage.setItem('ssoToken' ,ssoRes.token);
        }else{
            //未登录401
            window.localStorage.removeItem("ssoToken")
            window.localStorage.removeItem("gcsbUserToken")
        }
    }else{
        //接口请求失败
    }
    const userInfo =  window.localStorage.getItem('gcsbUserToken');
    const ssoToken = window.localStorage.getItem('ssoToken');
    if(ssoToken){

        //判断旧的ssoToken与新获取的是否相似   存在ssoToken有效 userToken未同步更新的情况  （解决用户从主网站退出登录，由于并无退出登录通知，未清除本网站usertoken，又在其他网站登录，然后手动跳转到本网站；）
        if(!userInfo||oldssoToken!=ssoToken){
            //未登录则执行登录逻辑
            ssoLogin(ssoToken).then(res=>{
                if(res.code==200 && res.token){
                    setToken(res.token)

                    next()
                }else{
                    if (routesMap[to.name]&& routesMap[to.name].auth && !userInfo){
                        next({ name: 'home' })
                    } else{
                        next()
                    }
                }
            }).catch(e=>{
                removeToken();
                if (routesMap[to.name]&& routesMap[to.name].auth && !userInfo){
                    next({ name: 'home' })
                } else{
                    next()
                }
            })
        }else{
            //已登录状态，检查sso token是否有效
            ssoCheck(ssoToken).then(res=>{
                if(res.code==200){
                    //sso 有效
                    // console.log('store.dispatch',store.dispatch)

                    next()
                }else{
                    //sso无效，退出
                    removeToken();
                    if (routesMap[to.name]&& routesMap[to.name].auth && !userInfo){
                        next({ name: 'home' })
                    } else{
                        next()
                    }
                }
            }).catch(e=>{
                //sso无效，退出
                removeToken();
                if (routesMap[to.name]&& routesMap[to.name].auth && !userInfo){
                    next({ name: 'home' })
                } else{
                    next()
                }
            })

        }

    }else{
        //无ssoToken，则清除登录
        removeToken();
        if (routesMap[to.name]&& routesMap[to.name].auth && !userInfo){
            next({ name: 'home' })
        } else{
            next()
        }
    }



})

export default router;

