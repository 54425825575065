<template>
<div style="">
  <h6 class="comment-title">评论</h6>
  <div>
    <el-input
      type="textarea"
      :rows="4"
      placeholder="请输入内容"
      v-model="textarea">
    </el-input>
    <div class="flexr justify-end padding10">
      <el-button @click="clearTextarea" size="small">清空</el-button>
      <el-button type="primary" size="small" @click="pushTextarea">发送</el-button>
    </div>
  </div>
  <ul class="comment-list">
    <li class="flexr  justify-between align-start" v-for="(item,index) in commetList" :key="index">
      <div class="flex1 flexr align-start">

        <div class="comment-avt">{{item.nameStr}}</div>
        <div class="flex1">
          <div class="flexr justify-between comment-info" >
            <h6 class="comment-name">{{item.nickName}}</h6>
            <div class="comment-Time">
              <p>{{item.createTime}}</p>
              <p class="deleteImg"> <img src="@/assets/images/delete.png" alt="" @click="deleteComment(item,index)"> </p>
            </div>
          </div>

          <p class="comment-content">{{item.content}}</p>
        </div>
      </div>

    </li>
  </ul>
</div>
</template>

<script>
import { queryFormReviewLog,addFormReview ,deleteFormReview} from '@/api/login'

export default {
  name: 'comment',
  props:{
    id:{
      type:[Number,String],
      default:null
    } ,
    stuId:{
      type:[Number,String],
      default:null
    }
  },
  data(){
    return{
      textarea:'',
      commetList:[]
    }
  },
  mounted(){
    this.getList()
  },
  methods: {
    deleteComment(item,index) {
      this.$confirm('是否确认删除此条评论?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        deleteFormReview({ id: item.id}).then(res => {

          this.getList()
        })
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });
      });
    },

    clearTextarea() {
      this.textarea = ''
    },
    pushTextarea() {
      if (this.textarea == '') return
      let params = {
        relaId: this.id,
        content: this.textarea,
        stuId: this.stuId
      }
      addFormReview(params).then(res => {
        this.clearTextarea()
        this.getList()
      })

    },
    getList() {
      queryFormReviewLog(this.id).then(res => {
        let commetList = res.data
        commetList.forEach(item=>{
          item.nameStr=''
          if(item.nickName){
            let name=String(item.nickName)
            console.log(typeof name,"-------name")
            item.nameStr=name.substring(name.length-1,name.length)
          }

        })
        this.commetList=commetList
        console.log(this.commetList, '-----this.commetList')
      })
    }
  }
}
</script>

<style lang="less" scoped>
.comment-list{
  padding-top: 20px;
  padding-right: 10px;
  li{
    padding: 10px 0;
    margin-bottom: 10px;
  }
}
.comment-title{
  margin:20px 0;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 18px;
  color: #333333;
  line-height: 18px;
  font-style: normal;
  padding-left: 20px;
  position: relative;
  &:after{
    content: '';
    width: 3px;
    height: 18px;
    background: #E77B20;
    border-radius: 2px;
    position: absolute;
    top:0;
    left: 0;
  }
}
.comment-avt{
  width: 50px;
  height: 50px;
  background: #6FA2FF;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 20px;
  color: #FFFFFF;
  line-height:50px;
  text-align: center;
  font-style: normal;
  border-radius:50%;
  margin-right: 20px;
  flex-shrink: 0;
}
.comment-info{
  //width: calc(100% -200px);
}
.comment-name{
  font-family: PingFangSC, PingFang SC;
  font-weight: 700;
  font-size: 16px;
  color: #333333;
  line-height: 26px;
  text-align: left;
  font-style: normal;
  //margin-bottom: 6px;
}
.comment-content{
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #666666;
  line-height: 26px;
  text-align: left;
  font-style: normal;
  width: 90%;
}

.comment-Time{
  position: relative;
}
.deleteImg{
position: absolute;
  top:26px;
  right: 0;
}
.comment-Time p{
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #999999;
  line-height: 26px;
  text-align: right;
  font-style: normal;
  margin-bottom: 5px;
  img{
    height: 16px;
    width: auto;
    cursor: pointer;
  }
}

@media only screen and (max-width: 750px) {

  .comment-avt{
    background-color: #E77B20;
    margin-right:10px
  }

}
</style>
