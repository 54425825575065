<template>
  <div id="app">
    <keep-alive :include="cachedViews">
    <router-view/>
    </keep-alive>
  </div>
</template>

<script>

export default {
  name: 'App',
  data(){
    return{
      cachedViews:['IndexPage']
    }
  }

}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #333;
  background: #F5F5F7;
  /*margin-top: 60px;*/
}
</style>
