<template>
  <div class="block" style="margin-top:30px">
    <el-timeline>
      <el-timeline-item v-for="(item,index) in logList" :key="index" :timestamp="item.createTime" placement="top">
        <div style="height: 10px"></div>
        <el-card shadow="never">
          <!--          内容-->
          <el-button type="primary" @click="handleUpdate(item)" size="mini">编辑</el-button>
          <ul class="label-content flexr align-start">
            <li v-for="(msg,i) in item.nameList" :key="i"><span class="name">{{msg.label}}：</span><span class="content">{{item[msg.fieldName]?item[msg.fieldName]:msg.value}}</span></li>
<!--            <li><span class="name">高校导师电话：</span><span class="content">1736273628</span></li>-->
<!--            <li><span class="name">学校课题专业方向是否与企业项目方向一致：</span><span class="content">是</span></li>-->
          </ul>
          <!--          附件-->
          <div>
            <file :list="item.filelist" :id="item.id"></file>
          </div>
          <!--          评论-->
          <div class="comment-wrap">
            <template v-if="item.showComment">
              <div>
                <comment ref="commentRef" :id="item.id" :stuId="params.stuId"></comment>
              </div>
              <div class="open-icon" @click="commentChange(item,index,false)">
                收起 <i class="el-icon-arrow-up"></i>
              </div>
            </template>
            <div v-else class="open-icon" @click="commentChange(item,index,true)">
              展开 <i class="el-icon-arrow-down"></i>
            </div>


          </div>
        </el-card>
      </el-timeline-item>

    </el-timeline>
  </div>
</template>

<script>
import { queryFormDataLog } from '@/api/login'
import file from './file'
import comment from "./comment.vue"
export default {
  name: 'timelineLog',
  components: {
    file,
    comment
  },
  data() {
    return {
      filelist: [],
      logList: [],
      params: {}
    }
  },

  methods: {
    handleUpdate(item){
      console.log(item)
      this.$emit('update',item)
    },
    commentChange(item,index,flag){
      item.showComment=flag,
        this.logList.splice(index,1,item)
    },
    getData(params) {
      console.log(11111)
      this.params = params
      this.getList()
    },
    clearList(){
      this.logList=[]
    },
    getList() {
      this.logList=[]
      queryFormDataLog(this.params).then(res => {
        let logList = res.data
        logList.forEach(item=>{
          item.showComment=false
          item.nameList=JSON.parse(item.nameJson)
          if(item.files){
            item.filelist=JSON.parse(item.files)
          }else{
            item.filelist=[]
          }

        })
        this.logList=logList
      })
    }
  }
}
</script>

<style lang="less" scoped>
.label-content {
  flex-wrap: wrap;

  li {
    width: 50%;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;

    line-height: 36px;

    font-style: normal;
    span{
      vertical-align: top;
    }
    .name {
      display: inline-block;
      color: #333333;
      width: 40%;
      text-align: right;
    }

    .content {
      display: inline-block;
      color: #666666;
      width: 59%;
    }

  }
}

.comment-wrap {
  margin-top: 40px;
  border-top: 1px solid #E1E8F2;
}

.open-icon{
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #E77B20;
  line-height: 16px;
  text-align: right;
  font-style: normal;
  padding:20px 10px;
  cursor: pointer;
i{
  font-size: 16px;
  color: #E77B20;
  font-weight: bold;
}
}
@media only screen and (max-width: 750px) {
  .label-content li{
    width: 100%;
  }
}
</style>
