import request from '@/utils/request'

//单点登录，sso login
export function ssoLogin(token) {
  return request({
    url: 'ssoLogin',
    headers: {
      isToken: false
    },
    method: 'post',
    data: {token:token}
  })
}

//单点登录，sso check
export function ssoCheck(token){
  return request({
    url: 'third/ssoCheck',
    headers: {
      isToken: false
    },
    method: 'post',
    data: {token:token},
  })
}

//获取导航菜单
export function getMemus(){
  return request({
    url: 'recruitment/HomeMenus',
    method: 'get',
  })
}

// 获取用户信息
export function getInfo() {
  return request({
    url: 'getInfo',
    method: 'get'
  })
}

export function getDicts(dictType) {
  return request({
    url: 'system/dict/data/type/' + dictType,
    method: 'get'
  })
}
//高校和企业登录的查询此接口
export function getMyStuList(params){
  return request({
    url: 'third/getMyStuList',
    method: 'get',
    params:params
  })
}
//学生查此接口
export function getMyStuInfo(params){
  return request({
    url: 'third/getMyStuInfo',
    method: 'get',
    params:params
  })
}
///third/saveFormData  提交表单
export function saveFormData(params){
  return request({
    url: 'third/saveFormData',
    method: 'post',
    data:params
  })
}
//编辑
export function editFormData(params){
  return request({
    url: 'third/editFormData',
    method: 'post',
    data:params
  })
}


//历史记录
export function queryFormDataLog(params) {
  return request({
    url: 'third/queryFormDataLog',
    method: 'get',
    params
  })
}
//评论列表
export function queryFormReviewLog(id) {
  return request({
    url: 'third/queryFormReviewLog?dataId='+id,
    method: 'get',
  })
}
//添加评论
export function addFormReview(data) {
  return request({
    url: 'third/addFormReview',
    method: 'post',
    data
  })
}
//删除评论
export function deleteFormReview(data) {
  return request({
    url: 'third/delFormReview',
    method: 'post',
    data:data
  })
}
//文件
export function addFormLogFile(data) {
  return request({
    url: 'third/addFormLogFile',
    method: 'post',
    data
  })
}
