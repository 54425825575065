import Vue from 'vue'
import App from './App.vue'
import store from './store'
Vue.prototype.$store=store
// 引入vue-router --------------------
// import VueRouter from 'vue-router'
import router from './router/index.js'
// Vue.use(VueRouter)
// ----------------------------------
import ElementUI from 'element-ui';

import 'element-ui/lib/theme-chalk/display.css';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/reset.css' // global css
import './assets/css/common.css'
import { download } from '@/utils/request'


import VFormRender from '../lib/vform/VFormRender.umd.js'
import '../lib/vform/VFormRender.css'


import topNav from "@/components/topNav"
import FileUpload from "@/components/FileUpload"

Vue.prototype.download = download

Vue.component('topNav', topNav)
Vue.component('FileUpload', FileUpload)

Vue.use(ElementUI);
Vue.use(VFormRender)  //注册了v-form-render等组件

Vue.config.productionTip = false
Vue.config.strict = false // 关闭严格模式


Vue.prototype.msgSuccess = function (msg) {
  this.$message({ showClose: true, message: msg, type: "success" });
}

Vue.prototype.msgError = function (msg) {
  this.$message({ showClose: true, message: msg, type: "error" });
}

Vue.prototype.msgInfo = function (msg) {
  this.$message.info(msg);
}

// 设置根元素font-size
function setRem(){
  // 获取屏幕宽度
  var w = document.documentElement.clientWidth;
  console.log(w,'-----')
  // if()
  if(window.top === window.self) {
    //不在iframe中
    document.documentElement.style.fontSize = w / 10 + 'px';
    console.log("--不在iframe中---------------")
  } else {
    document.documentElement.style.fontSize = w / 5.37 + 'px';
    //在iframe中
    console.log( window.location.hash,' window.location.href')

    console.log('在iframe中')
  }
  if(window.location.hash.indexOf('appSetForm')>-1){
    document.documentElement.style.fontSize = w / 2.4 + 'px';
  }

}
setRem();
window.addEventListener("resize", setRem);


new Vue({
  render: h => h(App),
  router: router,
  store,
}).$mount('#app')
