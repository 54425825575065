<template>
  <div>
    <topNav>
      <img class="sub-nav-icon" src="../assets/img-new/icon-home.png"/>
      <span class="sub-nav-item active" @click="gotoPage(1)">> 工程师协会 ></span>
      <span  class="sub-nav-item active" @click="gotoPage(2)"> 工程硕博士 ></span>
      <span class="sub-nav-item">工程硕博士服务平台</span>
    </topNav>
    <div v-loading="pageLoading">

      <div class="students-wrap flex" v-if="info.name||info.learningCategory||info.cultureState||info.experimentSchool||info.faculty||info.professionalName||info.joinEnterprise">
        <div class="students-avt">{{ nameStr }}</div>
        <div>
          <h6 class="students-name flex">{{ info.name }} <p v-if="info.learningCategory">{{ info.learningCategory }}</p></h6>
          <p class="students-info flex"><img src="../assets/image/icon3.png" alt=""> <span>培养状态：</span> <span
              :class="info.cultureState=='正常'?'status':'status1'">{{ info.cultureState }}</span></p>
          <p class="students-info flex">
            <img  src="../assets/image/icon2.png" alt="">
            <span>学校专业：</span>
            <span> {{ info.experimentSchool }}</span>
            <span>{{ info.faculty }}</span>
            <span>{{ info.professionalName }}</span></p>
          <p class="students-info flex">
            <img  src="../assets/image/icon1.png" alt="">
            <span>实际入企实践企业：</span>
            <span>{{ info.joinEnterprise }}</span></p>
        </div>
      </div>
      <div class="main-wrap padding_20">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane v-for="(item,index) in formRelaList" :key="index" :label="item.formName" :name="index+''">

          </el-tab-pane>
        </el-tabs>
          <div style="margin:10px;" class="flexr justify_center"  v-if="form.approveFlag=='1'">
            <p style="font-size: 16px;color: #F56C6C;background-color:rgba(245,108,108,0.2);padding:4px 10px;border-radius: 2px"> 修改待平台审核中，请稍后刷新查看！</p>

          </div>
        <div class="addresunmeBox" key="addresunmeBox">
          <v-form-render ref="vFormRef" key="vFormRef" v-if="showLoading">
          </v-form-render>
        </div>

        <div class="flex justify-center" v-if="form.formJson" style="margin-top: 20px">
          <!--      <el-button v-if="isRed" type="primary" @click="hanldeUpdateFormData" >编辑</el-button>-->
          <!--      <template v-else>-->
          <el-button type="" @click="hanldeCancle">返回</el-button>
          <el-button type="primary" @click="hanldeSaveFormData" :loading="loading" v-if="form.approveFlag=='2'">保存</el-button>
          <!--      </template>-->


        </div>
        <div v-if="form.childType==10 || form.childType == 12||form.childType==13">
          <timelineLog ref="timelineLogRef" @update="handleUpdate"></timelineLog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getInfo, getDicts, getMyStuInfo, saveFormData, updateApplyInfo, editFormData} from "@/api/login.js"
import timelineLog from "./components/timelineLog"
import {getToken} from "@/utils/auth";
import {arrayChangeKey} from "@/utils/index"
export default {
  name: 'Setform',
  components: {
    timelineLog
  },
  data() {
    return {
      activeName: '0',
      fromTypeOptions: [],
      form: {
        id: '',
        stuId: '',
        formId: '',
        formJson: '',
        nameJson: '',
        valueJson: ''
      },
      stuInfo: {},
      formRelaList: [],
      loading: false,
      nameStr: "",
      info: {},
      isRed: false,
      pageLoading:false,
      showLoading:true,
      userChildType:'',
      queryID:null,
      loginUserId:''
    }
  },
  mounted() {
    // getDicts('form_child_type').then(res=>{
    //   this.activeName=res.data[0].dictValue
    //   this.fromTypeOptions=res.data
    // })
    this.queryID=this.$route.query.id

    if (getToken()){
      getInfo().then(res => {
        this.stuInfo = res.stuInfo
        this.userChildType=res.user.childType
        this.loginUserId=res.user.userId
        // this.

        window.localStorage.setItem('stuId', this.queryID||this.stuInfo.stuId)
        this.handleGetMyStuInfo()
      })
    }
  },
  methods: {
    gotoPage(type){
      if(type==1){
        window.location.href=process.env.VUE_APP_BASE_SSO+"/second/EngineersInstitute"
      }else{
        window.location.href=process.env.VUE_APP_BASE_SSO+"/EngineersInstitute/MasterDoctor?title=工程硕博士"
      }
    },
    handleGetMyStuInfo() {
      // this.pageLoading=true
      this.showLoading=false

      getMyStuInfo({id: this.queryID||this.stuInfo.stuId}).then(res => {
        this.showLoading=true
        // this.pageLoading=false
        this.info = res.data
        if (this.info.name) {
          this.nameStr = this.info.name.substring(this.info.name.length - 2, this.info.name.length)
        }
        this.formRelaList = res.data.formRelaList
        // this.activeName='0'

          if (this.formRelaList && this.formRelaList.length > 0) {
            this.form = this.formRelaList[this.activeName]
            this.$nextTick(() => {
            //获取历史数据
            if (this.form.childType == 10|| this.form.childType == 12 || this.form.childType == 13) {
              if(this.$refs.timelineLogRef){
                this.$refs.timelineLogRef.clearList()
                this.$refs.timelineLogRef.getData({stuId: this.form.stuId, formId: this.form.formId})
              }

            }

            let formJson = this.form.formJson
            let valueJson = this.form.valueJson
            if (formJson) {
              this.$refs.vFormRef.setFormJson(JSON.parse(formJson))
            }
            if (valueJson) {
              let valueObj=this.getValue(valueJson)
              this.$refs.vFormRef.setFormData(valueObj)
              // this.isRed=true
              // this.$refs.vFormRef.setReadMode(true)
            }
              this.setForm()
            })
          }

      },()=>{
        this.pageLoading=false
      })
    },
    hanldeCancle() {
      window.history.back();
    },
    handleUpdate(item){
      this.form = {...item}

      //获取历史数据
      if (this.form.childType == 10 || this.form.childType == 12|| this.form.childType == 13) {
        this.$refs.timelineLogRef.clearList()
        this.$refs.timelineLogRef.getData({stuId: this.form.stuId, formId: this.form.formId})
      }
      //更新的不用重新加载表单，可以直接赋值回显
      let valueJson = this.form.valueJson
      if (valueJson) {
        let valueObj=this.getValue(valueJson)
        this.$refs.vFormRef.setFormData(valueObj)
      }

    },
    // hanldeUpdateFormData(){
    //   this.isRed=false
    //   this.$refs.vFormRef.setReadMode(false)
    // },
    //保存表单内容
    hanldeSaveFormData() {
      let widgets = this.$refs.vFormRef.getFieldWidgets()

      if (!this.form.formJson) return
      this.$refs.vFormRef.getFormData().then(formData => {
        let nameJson = []
        if (widgets && widgets.length > 0) {
          widgets.forEach((item) => {
            nameJson.push({
              label: item.field ? item.field.options.label : '',
              fieldName: item.name,
              value: formData[item.name]||''
            })
          })
        }
        for(var i in formData){
          formData[i]=formData[i]||''
        }
        let params = {
          ...this.form,
          valueJson: JSON.stringify(formData),
          nameJson: JSON.stringify(nameJson) == "{}" ? '' : JSON.stringify(nameJson),
        }
        //提交
        if (this.loading) {
          return;
        }

        //如果是课题开展情况和诉求就的编辑就调这个接口
        if((params.childType==10||params.childType==12||params.childType==13)&&params.id){
          delete params.nameList
          this.loading = true;
          editFormData(params).then(res => {
            this.loading = false;
            if (res.code == 200) {
              this.$refs.vFormRef.resetForm()
              this.$message.success('提交成功')
              this.handleGetMyStuInfo()
            } else {
              this.$message.error(res.msg ? res.msg : '提交失败');
            }

          }).catch(e => {
            this.loading = false;
          })
        }else{
        this.loading = true;
        saveFormData(params).then(res => {
          this.loading = false;
          if (res.code == 200) {
            this.$refs.vFormRef.resetForm()
            this.$message.success('提交成功')
            this.handleGetMyStuInfo()
          } else {
            this.$message.error(res.msg ? res.msg : '提交失败');
          }

        }).catch(e => {
          this.loading = false;
        })
        }


      }).catch(error => {
        // Form Validation failed
        // this.$message.error(error)
      })

    },
    //切换表单
    handleClick(e) {
      this.activeName = e.name
      this.form = this.formRelaList[e.name] || {}
      this.initForm()
    },
    initForm(){
      let formJson = this.form.formJson
      let valueJson = this.form.valueJson
      this.showLoading=false
      setTimeout(()=>{
        this.showLoading=true

        this.$nextTick(() => {
          //设置表单
          if (formJson) {
            this.$refs.vFormRef.setFormJson(JSON.parse(formJson))
          } else {
            this.$refs.vFormRef.setFormJson({})
          }

          //获取历史数据
          if (this.form.childType == 10 || this.form.childType == 12|| this.form.childType == 13) {
            this.$refs.timelineLogRef.clearList()
            this.$refs.timelineLogRef.getData({stuId: this.form.stuId, formId: this.form.formId})
          }
          if(this.form.childType == 12){
            //  假如是月报 就设置那些可填，那些不可填

          }
          //数据
          if (valueJson) {
            let valueObj=this.getValue(valueJson,formJson)
            this.$refs.vFormRef.setFormData(valueObj)
          } else {
            this.$refs.vFormRef.setFormData({})
          }
          this.setForm()

        })
      },200)
    },
    setForm(){
      setTimeout(()=>{
        let values = this.info||{}
        let userId = this.loginUserId
        let nameJson=JSON.parse(values.otherInfoJson)
        let nameJson14=nameJson?nameJson[14]:[]
        let nameObj={}
        if(nameJson14){
          nameObj=arrayChangeKey(nameJson14,'fieldName')
        }

        if(this.form.childType==12){
          if(this.userChildType==3&&this.form.approveFlag=='2'){
            this.$refs.vFormRef.disableForm()
            //学生
            // this.$refs.vFormRef.disableWidgets(['schoolTutorComment','schoolOpinion','schoolAssessResult','firmTutorComment','firmOpinion','firmAssessResult'])
            this.$refs.vFormRef.enableWidgets(['tutorAssessTwoId','firmAssessTwoId','cultureCycle','finishCondition','guidanceContent','learningObjective','workFinishCondition','tutorAssessId','firmAssessId'])
            // this.$refs.vFormRef.hideWidgets(['schoolAssessResult','firmAssessResult'])
            //给月报的导师赋值
            if (!this.form.valueJson) {

              //高校导师1
              let lpSchoolTutorValue=values.lpSchoolTutorIdText||nameObj['lpSchoolTutor'].value
              if (lpSchoolTutorValue) {
                this.$refs.vFormRef.setFieldValue('tutorAssessId', lpSchoolTutorValue)
              }
              let lpSchoolTutorTwoValue=values.lpSchoolTutorTwoIdText||nameObj['lpSchoolTutorTwo'].value
              if (lpSchoolTutorTwoValue) {
                //高校导师2
                this.$refs.vFormRef.setFieldValue('tutorAssessTwoId', lpSchoolTutorTwoValue)
              }
              let lpFirmTutorValue=values.lpFirmTutorIdText||nameObj['lpFirmTutor'].value
              if (lpFirmTutorValue) {
                //企业导师1
                this.$refs.vFormRef.setFieldValue('firmAssessId', lpFirmTutorValue)
              }
              let lpFirmTutorTwoValue=values.lpFirmTutorTwoIdText||nameObj['lpFirmTutorTwo'].value
              if (lpFirmTutorTwoValue) {
                //企业导师2
                this.$refs.vFormRef.setFieldValue('firmAssessTwoId', lpFirmTutorTwoValue)
              }

            }
          }else if(this.userChildType==1&&this.form.approveFlag=='2'){
            this.$refs.vFormRef.disableForm()
            if(values.lpSchoolTutorId&&values.lpSchoolTutorId==userId){
              //导师1
              this.$refs.vFormRef.enableWidgets(['schoolTutorComment', 'schoolOpinion', 'schoolAssessResult'])
            }else if(values.lpSchoolTutorTwoId&&values.lpSchoolTutorTwoId==userId){
              //导师2
              this.$refs.vFormRef.enableWidgets(['schoolTutorCommentTwo', 'schoolOpinionTwo', 'schoolAssessResultTwo'])
            }
          }else if(this.userChildType==2&&this.form.approveFlag=='2'){
            this.$refs.vFormRef.disableForm()
            //  企业
            // 根据id判断是导师1还是导师2
            if(values.lpFirmTutorId&&values.lpFirmTutorId==userId){
              //导师1
              this.$refs.vFormRef.enableWidgets(['firmTutorComment', 'firmOpinion', 'firmAssessResult'])
            }else if(values.lpFirmTutorTwoId&&values.lpFirmTutorTwoId==userId){
              //导师2
              this.$refs.vFormRef.enableWidgets(['firmTutorCommentTwo', 'firmOpinionTwo', 'firmAssessResultTwo'])
            }
            }else{
            //  其他
            if(this.form.approveFlag=='2'){
              this.$refs.vFormRef.enableForm()
            }else if(this.form.approveFlag=='1'){
              this.$refs.vFormRef.disableForm()
            }

          }

        }else{
          //给培养进展的导师赋值
          if (this.form.childType == 10 && !this.form.valueJson) {
            //高校导师
            let lpSchoolTutorValue=values.lpSchoolTutorIdText||nameObj['lpSchoolTutor'].value
            if (lpSchoolTutorValue) {
              this.$refs.vFormRef.setFieldValue('tutorId', lpSchoolTutorValue)
            }
            //企业导师
            let lpFirmTutorValue=values.lpFirmTutorIdText||nameObj['lpFirmTutor'].value
            if (lpFirmTutorValue) {
              this.$refs.vFormRef.setFieldValue('enterpriseMentorId', lpFirmTutorValue)
            }
          }
          if (this.form.childType==14){
            // 隐藏 园区管理员字段 parkManagerId
            this.$refs.vFormRef.hideWidgets(['parkManagerId'])
          }
          if(this.form.approveFlag=='2'){
            this.$refs.vFormRef.enableForm()
          }else if(this.form.approveFlag=='1'){
            this.$refs.vFormRef.disableForm()
          }

        }
      },100)
    },
    getValue(value,formJson){

      let val = JSON.parse(value)
      if(this.form.childType==14){
        //这四个字段以前是单选，后改为多选，加判断是为了兼容之前的单选，使单选的内容可以在多选中回显
        if(val['lpCollegeCharge']&&!Array.isArray(val['lpCollegeCharge'])){
          //  高校学院联培负责人
          val['lpCollegeCharge']=[val['lpCollegeCharge']]
        }
        if(val['lpSchoolCharge']&&!Array.isArray(val['lpSchoolCharge'])){
          //  高校学院联培负责人
          val['lpSchoolCharge']=[val['lpSchoolCharge']]
        }
        if(val['lpFirmClerk']&&!Array.isArray(val['lpFirmClerk'])){
          //  企业联培秘书
          val['lpFirmClerk']=[val['lpFirmClerk']]
        }
        if(val['lpFirmCharge']&&!Array.isArray(val['lpFirmCharge'])){
          //  企业联培负责人
          val['lpFirmCharge']=[val['lpFirmCharge']]

        }

      }

      return val
    },

  }
}
</script>

<style lang="less" scoped>
.students-wrap {
  width: 1230px;
  min-height: 140px;
  margin: 20px auto;
  background: url("../assets/image/bg1.png") no-repeat;
  background-size: cover;
  padding: 20px;
}

.students-avt {
  width: 100px;
  height: 100px;
  background: #6FA2FF;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 26px;
  color: #FFFFFF;
  line-height: 100px;
  text-align: center;
  font-style: normal;
  border-radius: 50%;
  margin-right: 20px;


}

.students-name {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 18px;
  color: #333333;
  line-height: 16px;
  text-align: left;
  font-style: normal;
  margin-bottom: 12px;

  p {
    margin-left: 10px;
    min-width: 26px;
    height: 20px;
    background: url("../assets/image/icon4.png") no-repeat;
    background-size: cover;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 20px;
    text-align: left;
    font-style: normal;
    padding: 0 10px;
  }
}

.students-info {
  height: 24px;
  img {
    width: 14px;
    height: 14px;
    margin-right: 7px;
  }

  span {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    line-height: 24px;
    text-align: left;
    font-style: normal;
    margin-right: 5px;
  }

  .status {
    color: #00BF5F;
  }

  .status1 {
    color: #E77B20;
  }
}


::v-deep .el-tabs__nav-next,::v-deep .el-tabs__nav-prev{
  font-size: 18px;
}
</style>
