<template>
<div>

</div>
</template>

<script>
import {getInfo} from "@/api/login";


export default {
  name: "page",
  created() {
    getInfo().then(res=>{
      if(res.code==200&&res.user&&res.user.childType){

        if(res.user.childType==3){
          this.$router.push('/setform')
        }else if(res.user.childType==2||res.user.childType==1||res.user.childType==4){

          this.$router.push('/index')
        }
      }
    })
  },
  methods:{

  }
}
</script>

<style scoped>

</style>
